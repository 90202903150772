import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'login-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './login-button.component.html',
  styleUrl: './login-button.component.scss'
})

export class LoginButtonComponent {
  private auth = inject(AuthService);

  public handleLogin(): void {
    this.auth.loginWithRedirect({
      appState: {
        target: '/mis-inmuebles',
      },
      authorizationParams: {
        prompt: 'login',
      },
    });
  }
}
